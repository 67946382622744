@charset "UTF-8";
.color-blue[data-v-65af85a3] {
  color: #033d4f !important;
}
.color-orange[data-v-65af85a3] {
  color: #f87e2b !important;
}
.color-gray[data-v-65af85a3] {
  color: #404040 !important;
}
.color-white[data-v-65af85a3] {
  color: white;
}
.bg-orange[data-v-65af85a3] {
  background-color: #f87e2b;
}
.bg-gray[data-v-65af85a3] {
  background-color: #A2A2A2;
}
.font-bold[data-v-65af85a3] {
  font-weight: 700;
}
.list-style-type-none[data-v-65af85a3] {
  list-style-type: none;
}
.line-height-1[data-v-65af85a3] {
  line-height: 1;
}
.line-height-1-5[data-v-65af85a3] {
  line-height: 1.5;
}
.line-height-2[data-v-65af85a3] {
  line-height: 2;
}
.font-weight-bold[data-v-65af85a3] {
  font-weight: 700;
}
.font-weight-light[data-v-65af85a3] {
  font-weight: 400;
}
.font-size-small[data-v-65af85a3] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium[data-v-65af85a3] {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big[data-v-65af85a3] {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large[data-v-65af85a3] {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify[data-v-65af85a3] {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small[data-v-65af85a3] {
    font-size: 14px;
}
.font-size-medium[data-v-65af85a3] {
    font-size: 16px;
}
.font-size-big[data-v-65af85a3] {
    font-size: 21px;
}
.font-size-large[data-v-65af85a3] {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small[data-v-65af85a3] {
    font-size: 14px;
}
.font-size-medium[data-v-65af85a3] {
    font-size: 14px;
}
.font-size-big[data-v-65af85a3] {
    font-size: 16px;
}
.font-size-large[data-v-65af85a3] {
    font-size: 21px;
}
}
.process-structure[data-v-65af85a3] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 125px 0 175px 0;
}
.process-structure .auth-process-body[data-v-65af85a3] {
  width: 95vw;
  max-width: 600px;
}
.process-structure .apply-process-body[data-v-65af85a3] {
  width: 95vw;
  max-width: 1280px;
  padding: 0 50px;
}
.auth-form-block[data-v-65af85a3] {
  width: 100%;
  max-width: 600px;
}
.title-tips[data-v-65af85a3] {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #404040;
  text-align: center;
}
.submit-btn[data-v-65af85a3] {
  width: 100%;
  color: white;
  background-color: #f87e2b;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.disable-btn[data-v-65af85a3] {
  width: 100%;
  background-color: #808080;
  color: white;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: not-allowed;
}
.v-stepper[data-v-65af85a3] {
  box-shadow: none !important;
}
.v-stepper__label[data-v-65af85a3] {
  text-align: center !important;
  line-height: 1.2;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label[data-v-65af85a3] {
  text-shadow: 0px 0px 0px #f87e2b !important;
  color: #f87e2b !important;
}
.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label[data-v-65af85a3] {
  text-shadow: 0px 0px 0px #f87e2b;
  color: #f87e2b !important;
}
.stepper[data-v-65af85a3] {
  margin-bottom: 50px;
}
@media only screen and (max-width: 960px) {
.v-stepper:not(.v-stepper--vertical) .v-stepper__label[data-v-65af85a3] {
    display: block !important;
}
}
@media only screen and (max-width: 600px) {
.process-structure[data-v-65af85a3] {
    padding: 40px 0 100px 0;
}
.v-stepper__header[data-v-65af85a3] {
    justify-content: center !important;
    box-shadow: none !important;
}
.v-stepper__header .v-stepper__step[data-v-65af85a3] {
    padding-bottom: 0;
}
.v-stepper__header .v-divider[data-v-65af85a3] {
    display: none;
}
}
.border-orange-input fieldset[data-v-65af85a3] {
  border: 1px solid #f87e2b !important;
}
.border-orange-input.v-input--is-focused fieldset[data-v-65af85a3] {
  border: 2px solid #f87e2b !important;
}
.v-text-field.v-text-field--enclosed[data-v-65af85a3] {
  margin-bottom: 15px !important;
}
.white-btn[data-v-65af85a3] {
  border: 1px solid #f87e2b;
  background-color: white;
  color: #f87e2b;
}
.blue-btn[data-v-65af85a3] {
  color: white;
  background-color: #033d4f;
}
.orange-btn[data-v-65af85a3] {
  color: white;
  background-color: #f87e2b;
}
.popup[data-v-65af85a3] {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
}
.popup .mask[data-v-65af85a3] {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.popup .panel[data-v-65af85a3] {
  position: absolute;
  z-index: 501;
  background-color: white;
  width: 70vw;
  box-shadow: 2.56036px 2.56036px 17.0691px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  top: 15vh;
  left: 50vw;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.popup .panel .close-icon[data-v-65af85a3] {
  position: absolute;
  top: 27px;
  right: 35px;
  color: #f87e2b;
  font-size: 32px;
  cursor: pointer;
}
.popup .panel .checkbox-box[data-v-65af85a3] {
  display: flex;
  justify-content: center;
}
.popup .panel .actions[data-v-65af85a3] {
  display: flex;
  justify-content: center;
}
.popup .panel .actions .btn[data-v-65af85a3] {
  width: 160px;
  height: 53px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.popup .panel .actions .btn[data-v-65af85a3]:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 600px) {
.popup .panel[data-v-65af85a3] {
    width: 95vw;
}
.popup .panel .actions .btn[data-v-65af85a3] {
    width: 140px;
    height: 48px;
    font-size: 14px;
}
}
html[data-v-65af85a3] {
  scroll-behavior: smooth !important;
}
.panel-item[data-v-65af85a3] {
  border: 1px solid #f87e2b;
  border-bottom: 0px;
}
.panel-item[data-v-65af85a3]:last-child {
  border-bottom: 1px solid #f87e2b;
}
.column-center[data-v-65af85a3] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-overlay[data-v-65af85a3] {
  z-index: 99999 !important;
}
.page[data-v-65af85a3] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.web-content[data-v-65af85a3] {
  width: 100vw;
  max-width: 1440px;
}
.page-center[data-v-65af85a3] {
  display: flex;
  justify-content: center;
}
.page-banner[data-v-65af85a3] {
  margin-bottom: 80px;
}
.page-banner img[data-v-65af85a3] {
  width: 100vw;
}
.desktop-banner[data-v-65af85a3] {
  display: block;
}
.mobile-banner[data-v-65af85a3] {
  display: none;
}
.page-structure[data-v-65af85a3] {
  display: flex;
  justify-content: space-between;
  padding: 0 85px;
  width: 100%;
  position: relative;
}
.page-structure .page-content[data-v-65af85a3] {
  max-width: 1010px;
  width: calc(100% - 200px);
}
.normal-page .block[data-v-65af85a3] {
  width: 100%;
}
.normal-page .block[data-v-65af85a3]:not(:last-child) {
  margin-bottom: 50px;
}
.normal-page .block-title[data-v-65af85a3] {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.normal-page .block-title .dot[data-v-65af85a3] {
  width: 10px;
  height: 10px;
  margin-right: 17px;
  background-color: #f87e2b;
}
.normal-page .block-sub-title[data-v-65af85a3] {
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
}
.normal-page .sub-block .sub-title[data-v-65af85a3] {
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.normal-page .sub-block .sub-title .title-tips[data-v-65af85a3] {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
}
.normal-page .sub-block[data-v-65af85a3]:not(:last-child) {
  margin-bottom: 30px;
}
.process-page-title-row[data-v-65af85a3] {
  display: flex;
  justify-content: center;
}
.process-page-title[data-v-65af85a3] {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #404040;
  padding-bottom: 12px;
  border-bottom: 3px solid #f87e2b;
  text-align: center;
}
@media screen and (max-width: 1024px) {
.page-structure .page-content[data-v-65af85a3] {
    width: 100%;
}
}
@media screen and (max-width: 960px) {
.desktop-banner[data-v-65af85a3] {
    display: none;
}
.page-structure[data-v-65af85a3] {
    padding: 0 30px;
}
.mobile-banner[data-v-65af85a3] {
    display: block;
}
.normal-page .block-title[data-v-65af85a3] {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;
}
.normal-page .block-title .dot[data-v-65af85a3] {
    width: 5px;
    height: 5px;
    margin-right: 11px;
}
.normal-page .sub-block .sub-title[data-v-65af85a3] {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}
.normal-page .sub-block .sub-title .title-tips[data-v-65af85a3] {
    font-size: 12px;
}
.normal-page .sub-block[data-v-65af85a3]:not(:last-child) {
    margin-bottom: 30px;
}
}
.v-input[data-v-65af85a3] {
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 600px) {
.page-banner[data-v-65af85a3] {
    margin-bottom: 30px;
}
.page-structure[data-v-65af85a3] {
    padding: 0 30px;
}
}
@media screen and (max-width: 400px) {
.page-structure[data-v-65af85a3] {
    padding: 0 15px;
}
}
.color-blue[data-v-65af85a3] {
  color: #033d4f !important;
}
.color-orange[data-v-65af85a3] {
  color: #f87e2b !important;
}
.color-gray[data-v-65af85a3] {
  color: #404040 !important;
}
.color-white[data-v-65af85a3] {
  color: white;
}
.bg-orange[data-v-65af85a3] {
  background-color: #f87e2b;
}
.bg-gray[data-v-65af85a3] {
  background-color: #A2A2A2;
}
.font-bold[data-v-65af85a3] {
  font-weight: 700;
}
.list-style-type-none[data-v-65af85a3] {
  list-style-type: none;
}
.line-height-1[data-v-65af85a3] {
  line-height: 1;
}
.line-height-1-5[data-v-65af85a3] {
  line-height: 1.5;
}
.line-height-2[data-v-65af85a3] {
  line-height: 2;
}
.font-weight-bold[data-v-65af85a3] {
  font-weight: 700;
}
.font-weight-light[data-v-65af85a3] {
  font-weight: 400;
}
.font-size-small[data-v-65af85a3] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium[data-v-65af85a3] {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big[data-v-65af85a3] {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large[data-v-65af85a3] {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify[data-v-65af85a3] {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small[data-v-65af85a3] {
    font-size: 14px;
}
.font-size-medium[data-v-65af85a3] {
    font-size: 16px;
}
.font-size-big[data-v-65af85a3] {
    font-size: 21px;
}
.font-size-large[data-v-65af85a3] {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small[data-v-65af85a3] {
    font-size: 14px;
}
.font-size-medium[data-v-65af85a3] {
    font-size: 14px;
}
.font-size-big[data-v-65af85a3] {
    font-size: 16px;
}
.font-size-large[data-v-65af85a3] {
    font-size: 21px;
}
}
.active[data-v-65af85a3] {
  color: white;
  position: relative;
}
.active[data-v-65af85a3]::before {
  content: "•";
  position: absolute;
  right: 110%;
}
.unactive[data-v-65af85a3] {
  color: #f87e2b;
}
.nav[data-v-65af85a3] {
  width: 100vw;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid #f97e2b;
  height: 100px;
  background-color: white;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}
.nav .profile-btn[data-v-65af85a3] {
  color: white !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
}
.nav .profile-btn .user-name[data-v-65af85a3] {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nav .profile-btn .profile-icon[data-v-65af85a3] {
  margin-right: 5px;
}
.nav .content[data-v-65af85a3] {
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav .content .is-mobile[data-v-65af85a3] {
  display: none;
}
.nav .content .logo[data-v-65af85a3] {
  width: 140px;
  height: 51px;
  cursor: pointer;
}
.nav .content .menu[data-v-65af85a3] {
  display: flex;
  height: 100%;
  color: #404040;
}
.nav .content .menu .item[data-v-65af85a3] {
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 125px;
  margin: 0 1px;
}
.nav .content .menu .item .text[data-v-65af85a3] {
  text-align: center;
  white-space: pre-line;
}
.nav .content .menu .item[data-v-65af85a3]:hover {
  color: white;
  background-color: #f87e2b;
}
.nav .content .menu .router-active[data-v-65af85a3] {
  color: white;
  background-color: #f87e2b;
  padding: 0 2px;
}
.nav .content .action[data-v-65af85a3] {
  display: flex;
}
.nav .content .action .login[data-v-65af85a3] {
  cursor: pointer;
  text-decoration: none;
  color: #cccccc;
  letter-spacing: 0.1em;
}
.nav .content .action .divide[data-v-65af85a3] {
  border: 1px solid #cccccc;
  margin: 0 16px;
}
.nav .content .action .language[data-v-65af85a3] {
  color: #cccccc;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.nav .mobile-menu[data-v-65af85a3] {
  letter-spacing: 0.1em;
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 1000;
  background-color: #033d4f;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  overflow: scroll;
  padding: 90px 60px 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.25s ease-in-out;
}
.nav .mobile-menu .member-detail .detail[data-v-65af85a3] {
  color: #f87e2b;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}
.nav .mobile-menu .member-detail .detail .profile-icon[data-v-65af85a3] {
  color: #f87e2b;
  font-size: 21px;
  margin-right: 5px;
}
.nav .mobile-menu .member-detail .action .action-item[data-v-65af85a3] {
  display: block;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  text-decoration: none;
}
.nav .mobile-menu .member-detail .action .action-item[data-v-65af85a3]:not(:last-child) {
  margin-bottom: 30px;
}
.nav .mobile-menu .member-detail .action .action-item[data-v-65af85a3]:last-child {
  margin-bottom: 15px;
}
.nav .mobile-menu .login[data-v-65af85a3] {
  color: white;
  text-decoration: none;
}
.nav .mobile-menu .member[data-v-65af85a3] {
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #f87e2b;
  color: white;
}
.nav .mobile-menu .menu[data-v-65af85a3] {
  margin-top: 30px;
  border-bottom: 1px solid #f87e2b;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.nav .mobile-menu .menu .item[data-v-65af85a3] {
  text-decoration: none;
  color: #f87e2b;
  margin-bottom: 30px;
  font-size: 16px;
}
.nav .mobile-menu .menu .router-active[data-v-65af85a3] {
  color: white;
}
.nav .mobile-menu .language[data-v-65af85a3] {
  display: flex;
  padding-top: 13px;
  margin-bottom: 30px;
}
.nav .mobile-menu .language .label[data-v-65af85a3] {
  color: #f87e2b;
}
.nav .mobile-menu .language .label .icon[data-v-65af85a3] {
  color: #f87e2b;
}
.nav .mobile-menu .language .item[data-v-65af85a3] {
  color: white;
  margin-left: 13px;
}
.nav .mobile-menu .footer .sns-box[data-v-65af85a3] {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.nav .mobile-menu .footer .sns-box .sns img[data-v-65af85a3] {
  width: 40px;
  height: 40px;
}
.nav .mobile-menu .footer .sns-box .sns[data-v-65af85a3]:not(:last-child) {
  margin-right: 32px;
}
.nav .mobile-menu .footer .terms[data-v-65af85a3] {
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
}
.nav .mobile-menu .footer .terms a[data-v-65af85a3] {
  color: white;
  text-decoration: none;
}
.nav .mobile-menu .footer .copy-right[data-v-65af85a3] {
  text-align: center;
  color: white;
}
.nav .mobile-menu.open[data-v-65af85a3] {
  left: 0;
}
@media screen and (max-width: 1280px) {
.nav .content .is-desktop[data-v-65af85a3] {
    display: none !important;
}
.nav .content .is-mobile[data-v-65af85a3] {
    display: block;
}
.nav .content .hamburger[data-v-65af85a3] {
    width: 35px;
    height: 33px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1001;
}
.nav .content .hamburger span[data-v-65af85a3] {
    background-color: #f87e2b;
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transform-origin: left center;
    transition: 0.25s ease-in-out;
}
.nav .content .hamburger span[data-v-65af85a3]:nth-child(1) {
    top: 0px;
}
.nav .content .hamburger span[data-v-65af85a3]:nth-child(2) {
    background-color: #033d4f;
    top: 12px;
}
.nav .content .hamburger span[data-v-65af85a3]:nth-child(3) {
    top: 24px;
}
.nav .content .hamburger.open span[data-v-65af85a3]:nth-child(1) {
    transform: rotate(45deg);
}
.nav .content .hamburger.open span[data-v-65af85a3]:nth-child(2) {
    width: 0%;
    opacity: 0;
}
.nav .content .hamburger.open span[data-v-65af85a3]:nth-child(3) {
    transform: rotate(-45deg);
}
.nav .mobile-menu .footer .terms[data-v-65af85a3] {
    font-size: 12px;
}
.nav .mobile-menu .footer .copy-right[data-v-65af85a3] {
    font-size: 12px;
}
}