.color-blue[data-v-4c15e5ba] {
  color: #033d4f !important;
}
.color-orange[data-v-4c15e5ba] {
  color: #f87e2b !important;
}
.color-gray[data-v-4c15e5ba] {
  color: #404040 !important;
}
.color-white[data-v-4c15e5ba] {
  color: white;
}
.bg-orange[data-v-4c15e5ba] {
  background-color: #f87e2b;
}
.bg-gray[data-v-4c15e5ba] {
  background-color: #A2A2A2;
}
.font-bold[data-v-4c15e5ba] {
  font-weight: 700;
}
.list-style-type-none[data-v-4c15e5ba] {
  list-style-type: none;
}
.line-height-1[data-v-4c15e5ba] {
  line-height: 1;
}
.line-height-1-5[data-v-4c15e5ba] {
  line-height: 1.5;
}
.line-height-2[data-v-4c15e5ba] {
  line-height: 2;
}
.font-weight-bold[data-v-4c15e5ba] {
  font-weight: 700;
}
.font-weight-light[data-v-4c15e5ba] {
  font-weight: 400;
}
.font-size-small[data-v-4c15e5ba] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium[data-v-4c15e5ba] {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big[data-v-4c15e5ba] {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large[data-v-4c15e5ba] {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify[data-v-4c15e5ba] {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small[data-v-4c15e5ba] {
    font-size: 14px;
}
.font-size-medium[data-v-4c15e5ba] {
    font-size: 16px;
}
.font-size-big[data-v-4c15e5ba] {
    font-size: 21px;
}
.font-size-large[data-v-4c15e5ba] {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small[data-v-4c15e5ba] {
    font-size: 14px;
}
.font-size-medium[data-v-4c15e5ba] {
    font-size: 14px;
}
.font-size-big[data-v-4c15e5ba] {
    font-size: 16px;
}
.font-size-large[data-v-4c15e5ba] {
    font-size: 21px;
}
}
.process-structure[data-v-4c15e5ba] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 125px 0 175px 0;
}
.process-structure .auth-process-body[data-v-4c15e5ba] {
  width: 95vw;
  max-width: 600px;
}
.process-structure .apply-process-body[data-v-4c15e5ba] {
  width: 95vw;
  max-width: 1280px;
  padding: 0 50px;
}
.auth-form-block[data-v-4c15e5ba] {
  width: 100%;
  max-width: 600px;
}
.title-tips[data-v-4c15e5ba] {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #404040;
  text-align: center;
}
.submit-btn[data-v-4c15e5ba] {
  width: 100%;
  color: white;
  background-color: #f87e2b;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.disable-btn[data-v-4c15e5ba] {
  width: 100%;
  background-color: #808080;
  color: white;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: not-allowed;
}
.v-stepper[data-v-4c15e5ba] {
  box-shadow: none !important;
}
.v-stepper__label[data-v-4c15e5ba] {
  text-align: center !important;
  line-height: 1.2;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label[data-v-4c15e5ba] {
  text-shadow: 0px 0px 0px #f87e2b !important;
  color: #f87e2b !important;
}
.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label[data-v-4c15e5ba] {
  text-shadow: 0px 0px 0px #f87e2b;
  color: #f87e2b !important;
}
.stepper[data-v-4c15e5ba] {
  margin-bottom: 50px;
}
@media only screen and (max-width: 960px) {
.v-stepper:not(.v-stepper--vertical) .v-stepper__label[data-v-4c15e5ba] {
    display: block !important;
}
}
@media only screen and (max-width: 600px) {
.process-structure[data-v-4c15e5ba] {
    padding: 40px 0 100px 0;
}
.v-stepper__header[data-v-4c15e5ba] {
    justify-content: center !important;
    box-shadow: none !important;
}
.v-stepper__header .v-stepper__step[data-v-4c15e5ba] {
    padding-bottom: 0;
}
.v-stepper__header .v-divider[data-v-4c15e5ba] {
    display: none;
}
}
.border-orange-input fieldset[data-v-4c15e5ba] {
  border: 1px solid #f87e2b !important;
}
.border-orange-input.v-input--is-focused fieldset[data-v-4c15e5ba] {
  border: 2px solid #f87e2b !important;
}
.v-text-field.v-text-field--enclosed[data-v-4c15e5ba] {
  margin-bottom: 15px !important;
}
.white-btn[data-v-4c15e5ba] {
  border: 1px solid #f87e2b;
  background-color: white;
  color: #f87e2b;
}
.blue-btn[data-v-4c15e5ba] {
  color: white;
  background-color: #033d4f;
}
.orange-btn[data-v-4c15e5ba] {
  color: white;
  background-color: #f87e2b;
}
.popup[data-v-4c15e5ba] {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
}
.popup .mask[data-v-4c15e5ba] {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.popup .panel[data-v-4c15e5ba] {
  position: absolute;
  z-index: 501;
  background-color: white;
  width: 70vw;
  box-shadow: 2.56036px 2.56036px 17.0691px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  top: 15vh;
  left: 50vw;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.popup .panel .close-icon[data-v-4c15e5ba] {
  position: absolute;
  top: 27px;
  right: 35px;
  color: #f87e2b;
  font-size: 32px;
  cursor: pointer;
}
.popup .panel .checkbox-box[data-v-4c15e5ba] {
  display: flex;
  justify-content: center;
}
.popup .panel .actions[data-v-4c15e5ba] {
  display: flex;
  justify-content: center;
}
.popup .panel .actions .btn[data-v-4c15e5ba] {
  width: 160px;
  height: 53px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.popup .panel .actions .btn[data-v-4c15e5ba]:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 600px) {
.popup .panel[data-v-4c15e5ba] {
    width: 95vw;
}
.popup .panel .actions .btn[data-v-4c15e5ba] {
    width: 140px;
    height: 48px;
    font-size: 14px;
}
}
html[data-v-4c15e5ba] {
  scroll-behavior: smooth !important;
}
.panel-item[data-v-4c15e5ba] {
  border: 1px solid #f87e2b;
  border-bottom: 0px;
}
.panel-item[data-v-4c15e5ba]:last-child {
  border-bottom: 1px solid #f87e2b;
}
.column-center[data-v-4c15e5ba] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-overlay[data-v-4c15e5ba] {
  z-index: 99999 !important;
}
.page[data-v-4c15e5ba] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.web-content[data-v-4c15e5ba] {
  width: 100vw;
  max-width: 1440px;
}
.page-center[data-v-4c15e5ba] {
  display: flex;
  justify-content: center;
}
.page-banner[data-v-4c15e5ba] {
  margin-bottom: 80px;
}
.page-banner img[data-v-4c15e5ba] {
  width: 100vw;
}
.desktop-banner[data-v-4c15e5ba] {
  display: block;
}
.mobile-banner[data-v-4c15e5ba] {
  display: none;
}
.page-structure[data-v-4c15e5ba] {
  display: flex;
  justify-content: space-between;
  padding: 0 85px;
  width: 100%;
  position: relative;
}
.page-structure .page-content[data-v-4c15e5ba] {
  max-width: 1010px;
  width: calc(100% - 200px);
}
.normal-page .block[data-v-4c15e5ba] {
  width: 100%;
}
.normal-page .block[data-v-4c15e5ba]:not(:last-child) {
  margin-bottom: 50px;
}
.normal-page .block-title[data-v-4c15e5ba] {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.normal-page .block-title .dot[data-v-4c15e5ba] {
  width: 10px;
  height: 10px;
  margin-right: 17px;
  background-color: #f87e2b;
}
.normal-page .block-sub-title[data-v-4c15e5ba] {
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
}
.normal-page .sub-block .sub-title[data-v-4c15e5ba] {
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.normal-page .sub-block .sub-title .title-tips[data-v-4c15e5ba] {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
}
.normal-page .sub-block[data-v-4c15e5ba]:not(:last-child) {
  margin-bottom: 30px;
}
.process-page-title-row[data-v-4c15e5ba] {
  display: flex;
  justify-content: center;
}
.process-page-title[data-v-4c15e5ba] {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #404040;
  padding-bottom: 12px;
  border-bottom: 3px solid #f87e2b;
  text-align: center;
}
@media screen and (max-width: 1024px) {
.page-structure .page-content[data-v-4c15e5ba] {
    width: 100%;
}
}
@media screen and (max-width: 960px) {
.desktop-banner[data-v-4c15e5ba] {
    display: none;
}
.page-structure[data-v-4c15e5ba] {
    padding: 0 30px;
}
.mobile-banner[data-v-4c15e5ba] {
    display: block;
}
.normal-page .block-title[data-v-4c15e5ba] {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;
}
.normal-page .block-title .dot[data-v-4c15e5ba] {
    width: 5px;
    height: 5px;
    margin-right: 11px;
}
.normal-page .sub-block .sub-title[data-v-4c15e5ba] {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}
.normal-page .sub-block .sub-title .title-tips[data-v-4c15e5ba] {
    font-size: 12px;
}
.normal-page .sub-block[data-v-4c15e5ba]:not(:last-child) {
    margin-bottom: 30px;
}
}
.v-input[data-v-4c15e5ba] {
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 600px) {
.page-banner[data-v-4c15e5ba] {
    margin-bottom: 30px;
}
.page-structure[data-v-4c15e5ba] {
    padding: 0 30px;
}
}
@media screen and (max-width: 400px) {
.page-structure[data-v-4c15e5ba] {
    padding: 0 15px;
}
}
.color-blue[data-v-4c15e5ba] {
  color: #033d4f !important;
}
.color-orange[data-v-4c15e5ba] {
  color: #f87e2b !important;
}
.color-gray[data-v-4c15e5ba] {
  color: #404040 !important;
}
.color-white[data-v-4c15e5ba] {
  color: white;
}
.bg-orange[data-v-4c15e5ba] {
  background-color: #f87e2b;
}
.bg-gray[data-v-4c15e5ba] {
  background-color: #A2A2A2;
}
.font-bold[data-v-4c15e5ba] {
  font-weight: 700;
}
.list-style-type-none[data-v-4c15e5ba] {
  list-style-type: none;
}
.line-height-1[data-v-4c15e5ba] {
  line-height: 1;
}
.line-height-1-5[data-v-4c15e5ba] {
  line-height: 1.5;
}
.line-height-2[data-v-4c15e5ba] {
  line-height: 2;
}
.font-weight-bold[data-v-4c15e5ba] {
  font-weight: 700;
}
.font-weight-light[data-v-4c15e5ba] {
  font-weight: 400;
}
.font-size-small[data-v-4c15e5ba] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium[data-v-4c15e5ba] {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big[data-v-4c15e5ba] {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large[data-v-4c15e5ba] {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify[data-v-4c15e5ba] {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small[data-v-4c15e5ba] {
    font-size: 14px;
}
.font-size-medium[data-v-4c15e5ba] {
    font-size: 16px;
}
.font-size-big[data-v-4c15e5ba] {
    font-size: 21px;
}
.font-size-large[data-v-4c15e5ba] {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small[data-v-4c15e5ba] {
    font-size: 14px;
}
.font-size-medium[data-v-4c15e5ba] {
    font-size: 14px;
}
.font-size-big[data-v-4c15e5ba] {
    font-size: 16px;
}
.font-size-large[data-v-4c15e5ba] {
    font-size: 21px;
}
}
.home-block7[data-v-4c15e5ba] {
  background-image: url(../img/block-bg-bottom.21c6aa00.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 100px 150px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-block7 .text[data-v-4c15e5ba] {
  color: #033d4f;
  font-weight: 700;
  font-size: 28px;
  line-height: 2;
  letter-spacing: 0.1em;
  width: 50%;
  padding-right: 130px;
}
.home-block7 .lottie[data-v-4c15e5ba] {
  width: 50%;
}
@media screen and (max-width: 1080px) {
.home-block7[data-v-4c15e5ba] {
    flex-direction: column;
    padding: 100px 60px 80px 60px;
}
.home-block7 .text[data-v-4c15e5ba] {
    width: 100%;
    text-align: center;
    padding-right: 0;
    margin-bottom: 50px;
}
.home-block7 .lottie[data-v-4c15e5ba] {
    width: 100%;
}
}
@media screen and (max-width: 600px) {
.home-block7[data-v-4c15e5ba] {
    flex-direction: column;
    padding: 80px 20px 60px 20px;
}
.home-block7 .text[data-v-4c15e5ba] {
    width: 90vw;
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 30px;
}
}